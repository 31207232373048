<template>
  <div>
    <b-icon
      :icon="boostrap_icon"
      scale="1"
      :variant="variant_icon"
      :class="{ 'icon-help': !press_to_open, 'icon-pointer': press_to_open }"
      @click="showModal()"
      @mouseover="showModalMouseOver()"
      v-b-tooltip.v-secondary.nonInteractive="tooltip_text"
    ></b-icon>
    <b-modal
      :id="b_modal_ref"
      :title="modal_title"
      :size="modal_size"
      hide-footer
    >
      <slot></slot>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "InfoModal",
  props: {
    tooltip_text: {
      type: String,
      default: "",
    },
    b_modal_ref: {
      type: String,
      default: "info-circle-fill",
    },
    modal_size: {
      type: String,
      default: "lg",
    },
    modal_title: {
      type: String,
      default: "",
    },
    press_to_open: {
      type: Boolean,
      default: true,
    },
    boostrap_icon: {
      type: String,
      default: "info-circle-fill",
    },
    variant_icon: {
      type: String,
      default: "primary",
    },
  },
  methods: {
    showModal() {
      if (this.press_to_open) this.$bvModal.show(this.b_modal_ref);
    },
    showModalMouseOver() {
      if (!this.press_to_open) this.$bvModal.show(this.b_modal_ref);
    },
  },
};
</script>

<style scoped>
.icon-help {
  cursor: help;
}
.icon-pointer {
  cursor: pointer;
}
</style>